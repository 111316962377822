import { FastGalleryService } from '../../services/fastGalleryService';
import { convertDraftItemToWixCodeItem } from '../../utils/mediaConverters';
import { getViewMode } from '../../utils/utils';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  const initGallery = async () => {
    // @ts-expect-error
    const { galleryId } = flowAPI.controllerConfig.config;
    const fastGalleryService = new FastGalleryService({
      viewMode: getViewMode(flowAPI),
      draftGalleryId: galleryId,
      httpClient: flowAPI.httpClient,
    });
    const itemsResponse =
      await fastGalleryService.getGalleryItemsInCurrentContext();

    const wixSDKitems = convertDraftItemToWixCodeItem(itemsResponse);
    // @ts-expect-error
    $w('#fastGallery1').items = wixSDKitems;
  };

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      initGallery();
    },
    exports: {},
  };
});
